import React, { Component } from 'react'
import PT from 'prop-types'
import { Link } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import classNames from 'classnames'
import { navigate } from 'gatsby'

import Picture from 'src/components/Picture'
import ArrowIcon from 'src/components/SvgSprite/ArrowIcon'
import { systemsBranding, systemsWeb, linguamill, studioBranding, studioWeb } from './CasesCards'
import SvgSprite from '../SvgSprite'

import styles from './styles.module.scss'

SwiperCore.use([Pagination])

const otherCases = [
    {
        title: `Calaton Systems <br/> Brand Identity`,
        caseImg: systemsBranding,
        link: '/cases/calaton-systems-branding-web',
    },
    {
        title: 'Calaton Systems <br/> Web & UI/UX Design',
        caseImg: systemsWeb,
        link: '/cases/calaton-systems-web',
    },
    {
        title: 'Linguamill <br/> Branding & Web design',
        caseImg: linguamill,
        link: '/cases/linguamill',
    },
    {
        title: 'Calaton Studio <br/> Brand Identity',
        caseImg: studioBranding,
        link: '/cases/calaton-studio-branding-web',
    },
    {
        title: 'Calaton Studio <br/> Web & UI/UX Design',
        caseImg: studioWeb,
        link: '/cases/calaton-studio-web',
    },
]

class OtherCases extends Component {
    constructor(props) {
        super(props)
        this.swiperRef = React.createRef()
        this.state = {
            prevDisable: true,
            nextDisable: false,
        }
    }

    setDisable = swiper => {
        this.setState({ prevDisable: swiper.isBeginning })
        this.setState({ nextDisable: swiper.isEnd })
    }

    changeSlide = e => {
        const { swiper } = this.swiperRef.current
        if (e.currentTarget.getAttribute('data-slide') === 'next') {
            swiper.slideNext()
        }
        if (e.currentTarget.getAttribute('data-slide') === 'prev') {
            swiper.slidePrev()
        }
    }

    btnSeeAllCasesClickHandler = () => {
        sessionStorage.setItem('filterCases', this.props.filter)
    }

    render() {
        const { prevDisable, nextDisable } = this.state
        const containerSectionClass = classNames('container', styles.otherCases)
        const btnAllCases = classNames(styles.btnAllCases, 'btn btn__primary')
        const pagination = classNames(styles.pagination, 'pagination')

        const parameters = {
            slidesPerView: 1,
            spaceBetween: 10,
            pagination: {
                el: '.pagination',
                type: 'bullets',
                bulletActiveClass: styles.active,
                bulletClass: styles.bullet,
            },
            breakpoints: {
                700: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },
        }

        return (
            <section className={containerSectionClass}>
                <div className={styles.navigation}>
                    <div className={styles.prev} data-slide="prev" onClick={this.changeSlide}>
                        <ArrowIcon disabled={prevDisable}></ArrowIcon>
                    </div>
                    <div className={styles.next} data-slide="next" onClick={this.changeSlide}>
                        <ArrowIcon disabled={nextDisable}></ArrowIcon>
                    </div>
                </div>
                <h3 className={styles.title}>Other cases</h3>
                <Swiper
                    ref={this.swiperRef}
                    {...parameters}
                    onProgress={this.setDisable}
                    className={styles.swiper}
                >
                    {otherCases.map(({ title, caseImg, link }) => (
                        <SwiperSlide
                            className={styles.slide}
                            key={title}
                            onClick={() => navigate(link)}
                        >
                            <div className={styles.hoverOverlay}>
                                <h3
                                    className={styles.hoverOverlayTitle}
                                    dangerouslySetInnerHTML={{ __html: title }}
                                ></h3>
                                <button className={styles.viewProject}>View case</button>
                            </div>
                            <Picture className={styles.projectImg} pic={caseImg} />
                        </SwiperSlide>
                    ))}
                    <SwiperSlide>
                        <div className={styles.seeAllCases}>
                            <div className={styles.allCasesContainer}>
                                <div className={styles.blur}>
                                    <SvgSprite name="logoCalatonStudio"></SvgSprite>
                                </div>
                                <Link
                                    className={btnAllCases}
                                    to="/cases"
                                    onClick={this.btnSeeAllCasesClickHandler}
                                >
                                    See all cases
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <div className={pagination}></div>
                </Swiper>
            </section>
        )
    }
}

OtherCases.propTypes = {
    filter: PT.string,
}

export default OtherCases
