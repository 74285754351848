import React from 'react'

import BaseContainer from 'src/components/BaseContainer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import CalatonStudioWebCase from 'src/components/CalatonStudioWebCase'
import Meta from '../../components/Meta';

const metaData = {
    title: 'Calaton Studio Web & UI/UX Design',
    description: 'Calaton Studio we have many cases with completed works',
    keywords: 'Calaton Studio, cases',
}


const CalatonStudio = () => {
    return (
        <BaseContainer>
	        <Meta content={metaData} />
            <Header />
            <CalatonStudioWebCase />
            <Footer />
        </BaseContainer>
    )
}

export default CalatonStudio
