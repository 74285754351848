import React from 'react'

import Picture from '../../Picture'
import uiGuideCalatonMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIGuideCalaton.jpg'
import uiGuideCalatonMobileRetina from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIGuideCalaton@2x.jpg'
import uiGuideCalatonMobileDesktop from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIGuideCalaton.jpg'
import uiGuideCalatonMobileDesktopRetina from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIGuideCalaton@2x.jpg'
import uiButtonsMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIButtons.jpg'
import uiButtonsMobileRetina from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIButtons@2x.jpg'
import uiButtonsMobileDesktop from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIButtons.jpg'
import uiButtonsMobileDesktopRetina from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIButtons@2x.jpg'
import uiIconsMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIIcons.jpg'
import uiIconsMobileRetina from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIIcons@2x.jpg'
import uiIconsDesktop from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIIcons.jpg'
import uiIconsDesktopRetina from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIIcons@2x.jpg'

import styles from './styles.module.scss'

const UIKitImages = [
    {
        source: [
            {
                srcSet: uiGuideCalatonMobile,
                retina: uiGuideCalatonMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: uiGuideCalatonMobileDesktop,
                retina: uiGuideCalatonMobileDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: uiGuideCalatonMobileDesktop,
        retina: uiGuideCalatonMobileDesktopRetina,
        alt: 'uikit guide calaton studio',
    },
    {
        source: [
            {
                srcSet: uiButtonsMobile,
                retina: uiButtonsMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: uiIconsDesktop,
                retina: uiIconsDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: uiIconsDesktop,
        retina: uiIconsDesktopRetina,
        alt: 'uikit icons calaton studio',
    },
    {
        source: [
            {
                srcSet: uiIconsMobile,
                retina: uiIconsMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: uiButtonsMobileDesktop,
                retina: uiButtonsMobileDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: uiButtonsMobileDesktop,
        retina: uiButtonsMobileDesktopRetina,
        alt: 'uikit buttons calaton studio',
    },
]

const UiKit = () => {
    return (
        <section className={styles.uikit}>
            <div className="container">
                <h2 className={styles.title}>UI kit</h2>
                {UIKitImages.map((item, i) => (
                    <div className={styles.imgContainer} key={i}>
                        <Picture className={styles.picture} pic={item} />
                    </div>
                ))}
            </div>
        </section>
    )
}

export default UiKit
