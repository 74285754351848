import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'
import SvgSprite from '../../SvgSprite'

const StudioTitle = () => {
    const textTopClassNames = classNames('container', styles.text, styles.textTop)
    const textBottomClassNames = classNames('container', styles.text, styles.textBottom)

    return (
        <section className={styles.studioTitle}>
            <h1 className={styles.title}>
                Calaton Studio <span className={styles.break}>Web & UI/UX Design</span>
            </h1>
            <div className={styles.top}>
                <SvgSprite name="web" />
                <div className={textTopClassNames}>
                    <p>
                        The task was to create an identity for an IT company from scratch, with only
                        the name Calaton available. The preference was given to a minimalistic
                        modern style with a variable logo.
                    </p>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={textBottomClassNames}>
                    <p>
                        The light green color was chosen which symbolizes the birth of a new life,
                        calmness and confidence. In the process of work, we were able to find a
                        balance between the seriousness and manufacturability of the IT sphere and a
                        modern bright and playful creative accent.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default StudioTitle
