import React from 'react'

import SvgSprite from '../../SvgSprite'
import Picture from '../../Picture'
import landingMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/landing.jpg'
import landingMobileRetina from 'src/images/optimized/jpg/cases/calatonStudio/mobile/landing@2x.jpg'
import landingDesktop from 'src/images/optimized/jpg/cases/calatonStudio/desktop/landing.jpg'
import landingDesktopRetina from 'src/images/optimized/jpg/cases/calatonStudio/desktop/landing@2x.jpg'

import styles from './styles.module.scss'

const landingPageImage = {
    source: [
        {
            srcSet: landingMobile,
            retina: landingMobileRetina,
            media: '(max-width: 989px)',
        },
        {
            srcSet: landingDesktop,
            retina: landingDesktopRetina,
            media: '(min-width: 990px)',
        },
    ],
    src: landingDesktop,
    retina: landingDesktopRetina,
    alt: 'calaton studio case landing page',
}

const LandingPage = () => {
    return (
        <section className={styles.landing}>
            <div className="container">
                <h2 className={styles.title}>Landing page</h2>
                <div className={styles.imgContainer}>
                    <Picture className={styles.picture} pic={landingPageImage} />
                </div>
            </div>
            <SvgSprite name="logo-gray" className={styles.svgLogoGrayfirst} />
            <SvgSprite name="logo-gray" className={styles.svgLogoGraySecond} />
            <SvgSprite name="logo-gray" className={styles.svgLogoGrayThird} />
        </section>
    )
}

export default LandingPage
