import React from 'react'

import StudioTitle from './StudioTitle'
import UiKit from './UiKit'
import LandingPage from './LandingPage'
import OtherCases from '../OtherCases'
import LetsTalk from '../LetsTalk'
import webBrief from '../../docs/Web-brief.pdf'

const CalatonStudioWebCase = () => {
    return (
        <main>
            <StudioTitle />
            <UiKit />
            <LandingPage />
            <OtherCases filter="webDesign" />
            <LetsTalk brief={{ name: 'Web-brief', file: webBrief }} />
        </main>
    )
}

export default CalatonStudioWebCase
