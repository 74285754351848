import systemsBrandMobile from 'src/images/optimized/jpg/cases/calatonSystems/mobile/calatonSystemsBrandingMobile.jpg'
import retinaSystemsBrandMobile from 'src/images/optimized/jpg/cases/calatonSystems/mobile/calatonSystemsBrandingMobile@2x.jpg'
import systemsBrandCase from 'src/images/optimized/jpg/cases/calatonSystems/desktop/calatonSystemsBrandingDesktop.jpg'
import retinaSystemsBrandCase from 'src/images/optimized/jpg/cases/calatonSystems/desktop/calatonSystemsBrandingDesktop@2x.jpg'

import systemsWebMobile from 'src/images/optimized/jpg/cases/calatonSystems/mobile/calatonSystemsMobile.jpg'
import retinaSystemsWebMobile from 'src/images/optimized/jpg/cases/calatonSystems/mobile/calatonSystemsMobile@2x.jpg'
import systemsWebCase from 'src/images/optimized/jpg/cases/calatonSystems/desktop/calatonSystemsDesktop.jpg'
import retinaSystemsWebCase from 'src/images/optimized/jpg/cases/calatonSystems/desktop/calatonSystemsDesktop@2x.jpg'

import linguamillMobile from 'src/images/optimized/jpg/cases/linguamill/mobile/linguamillMobile.jpg'
import linguamillMobileRetina from 'src/images/optimized/jpg/cases/linguamill/mobile/linguamillMobile@2x.jpg'
import linguamillDesktop from 'src/images/optimized/jpg/cases/linguamill/desktop/linguamillDesktop.jpg'
import linguamillDesktopRetina from 'src/images/optimized/jpg/cases/linguamill/desktop/linguamillDesktop@2x.jpg'

import studioWebMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/calatonStudioMobile.jpg'
import retinaStudioWebMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/calatonStudioMobile@2x.jpg'
import studioWebCase from 'src/images/optimized/jpg/cases/calatonStudio/desktop/calatonStudioDesktop.jpg'
import retinaStudioWebCase from 'src/images/optimized/jpg/cases/calatonStudio/desktop/calatonStudioDesktop@2x.jpg'

import studioBrandMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/calatonStudioBrandingMobile.jpg'
import retinaStudioBrandMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/calatonStudioBrandingMobile@2x.jpg'
import studioBrandCase from 'src/images/optimized/jpg/cases/calatonStudio/desktop/calatonStudioBrandingDesktop.jpg'
import retinaStudioBrandCase from 'src/images/optimized/jpg/cases/calatonStudio/desktop/calatonStudioBrandingDesktop@2x.jpg'

export const systemsBranding = {
    source: [
        {
            srcSet: systemsBrandMobile,
            retina: retinaSystemsBrandMobile,
            media: '(max-width: 699px)',
        },
        {
            srcSet: systemsBrandCase,
            retina: retinaSystemsBrandCase,
            media: '(min-width: 700px)',
        },
    ],
    src: systemsBrandCase,
    retina: retinaSystemsBrandCase,
    alt: 'Calaton Systems branding',
}

export const systemsWeb = {
    source: [
        {
            srcSet: systemsWebMobile,
            retina: retinaSystemsWebMobile,
            media: '(max-width: 699px)',
        },
        {
            srcSet: systemsWebCase,
            retina: retinaSystemsWebCase,
            media: '(min-width: 700px)',
        },
    ],
    src: systemsWebCase,
    retina: retinaSystemsWebCase,
    alt: 'Calaton Systems web solutions',
}

export const linguamill = {
    source: [
        {
            srcSet: linguamillMobile,
            retina: linguamillMobileRetina,
            media: '(max-width: 699px)',
        },
        {
            srcSet: linguamillDesktop,
            retina: linguamillDesktopRetina,
            media: '(min-width: 700px)',
        },
    ],
    src: linguamillDesktop,
    retina: linguamillDesktopRetina,
    alt: 'Linguamill',
}

export const studioBranding = {
    source: [
        {
            srcSet: studioBrandMobile,
            retina: retinaStudioBrandMobile,
            media: '(max-width: 699px)',
        },
        {
            srcSet: studioBrandCase,
            retina: retinaStudioBrandCase,
            media: '(min-width: 700px)',
        },
    ],
    src: studioBrandCase,
    retina: retinaStudioBrandCase,
    alt: 'Calaton Studio branding',
}

export const studioWeb = {
    source: [
        {
            srcSet: studioWebMobile,
            retina: retinaStudioWebMobile,
            media: '(max-width: 699px)',
        },
        {
            srcSet: studioWebCase,
            retina: retinaStudioWebCase,
            media: '(min-width: 700px)',
        },
    ],
    src: studioWebCase,
    retina: retinaStudioWebCase,
    alt: 'Calaton Studio web solutions',
}
